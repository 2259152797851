import styled from "styled-components";

const SidebarWrapper = styled.div`
  .sidebarMenuContainer {
    height: 100%;
  }

  .sidebarMenuLogo {
    height: 70px;
    padding: 5px 20px 0;
    text-align: center;
    overflow: hidden;
    cursor: pointer;

    img {
      margin: 0 auto;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .sidebarMenuHolder {
    svg {
      width: 18px;
    }
  }

  .sidebarMenuContent {
    font-size: ${props => props.theme.text["f16"]};
    padding-top: 2em;
  }

  .sidebarMenuContentScroll {
    height: calc(100% - 100px);
  }

  .sidebarMenuFooter {
    padding: 5px 5px 2px;
    text-align: center;
    font-size: ${props => props.theme.text.f12};
    color: ${props => props.theme.color.grey["grey-1"]};
    height: 30px;
  }

  .navText {
    font-size: ${props => props.theme.text["f16"]};
  }

  .menuIcon {
    font-size: ${props => props.theme.text["f16"]};
  }

  .subMenu {
    margin-left: 7px; // to fix submenu indent
  }
`;

export default SidebarWrapper;
