const config = {
  apiGateway: {
    URL: "https://4e6wgsgpfb.execute-api.ca-central-1.amazonaws.com/v1/",
    AUTH: "https://pwlke935vf.execute-api.ca-central-1.amazonaws.com/v1/",
    REGION: "ca-central-1"
  },
  sso_enabled: true,
  sso_config: {
            auth_url: "cigam-prod.auth.ca-central-1.amazoncognito.com",
            identity_provider: "CIGAM-PROD-AD",
            scope: "openid+aws.cognito.signin.user.admin",
            grant_type: "authorization_code",
            client_id: "2173t0nvf9dcu7rdl0uhpbhibj",
            redirect_uri: "https://civision.cifinancial.com/authorize",
        },
  canadaPostApiKey: "KC57-CW86-RA32-WU23"
}

var siteConfig = {
  title: "CI Vision",
  fourlyticsId: 19,
  fourlyticsScreenRecording: false
};

var componentConfig = {
  
};

export {config, siteConfig, componentConfig}
